// import { Link as RouterLink } from "react-router-dom";
// sections
import { Stack, Typography, Box } from "@mui/material";
import Login from "../../sections/Auth/LoginForm";

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        sx={{
            height: "100vh"        
        }}
    >
        <Stack
            sx={{
                width: 320
            }}
        >
            <Stack spacing={2} sx={{ mb: 5, position: "relative"}}>
                <Typography variant="h4">Login to CRM</Typography>

                <Stack direction="row" spacing={0.5}>
                {/* <Typography variant="body2">New user?</Typography> */}

                {/* <Link
                    to={"/auth/register"}
                    component={RouterLink}
                    variant="subtitle2"
                >
                    Create an account
                </Link> */}
                </Stack>
            </Stack>
            {/* Form */}
            <Login />
        </Stack>
    </Box>
  );
}