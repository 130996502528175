import { Stack, Box } from "@mui/material";
import {useEffect, useState} from "react";
import SwitchService from "../services/SwitchService";
import CircularProgress from '@mui/material/CircularProgress';
import HostComponent from "../components/Host/HostComponent";

const Host = ({ networkId, hostIp }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [host, setHost] = useState({})
    const [problem, setProblem] = useState([])
    const [hostDownlinks, setHostDownlinks] = useState([])
    const [hostPorts, setHostPorts] = useState([])

    useEffect(() => {
        const fetcher = async () => {
            try {
                const responseHost = await SwitchService.getHost(networkId, hostIp);
                setHost(responseHost.data.host)
            } catch (e) {
                console.log(e);
            }
            try {
                const responseZabbixProblems = await SwitchService.getHostProblem(networkId, hostIp)
                setProblem(responseZabbixProblems.data.problems)
            } catch (e) {
                console.log(e);
            }
            try {
                const responseHostDownlinks = await SwitchService.getHostDownlinks(networkId, hostIp)
                setHostDownlinks(responseHostDownlinks.data.hosts)
            } catch (e) {
                console.log(e);
            }
            try {
                const responseHostPorts = await SwitchService.getHostPorts(networkId, hostIp)
                setHostPorts(responseHostPorts.data.ports)
            } catch (e) {
                console.log(e);
            }
            setIsLoading(true)
        };
        setIsLoading(false)
        setHost([])
        setProblem([])
        setHostDownlinks([])
        setHostPorts([])
        fetcher()
    }, [networkId, hostIp])

    return (
        <>
            {isLoading ?
                <Stack
                    sx={{
                        width: "100%", flexGrow: 1, overflowY: "scroll" 
                    }}
                >
                    <HostComponent 
                        networkId={networkId}
                        host={host}
                        problem={problem}
                        hostDownlinks={hostDownlinks}
                        hostPorts={hostPorts}
                    />
                </Stack>
                : 
                <Stack
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{
                        width: "100%",
                        height: "100%",
                        flexGrow: 1,
                    }}>
                        <CircularProgress />
                </Stack>
            }
        </>
    )
};
export default Host;