import { Stack, Box, Typography, Divider } from "@mui/material";
// import { tokens } from "../../theme";
import { fDateTime } from "../../utils/formatTime";
import { int2ip } from "../../utils/formatIpAddress";

const HostPortElement = ({ port, el }) => {
    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                width: "100%",
                borderRadius: 1,
            }}
            p={0.5}
        >
            <Stack
                p={1}
                spacing={2}
                sx={{
                    width: "100%",
                    flex: "1"
                }}
            >
                <Typography>Interface {port}</Typography>
                <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 2 }}
                    useFlexGap
                    sx={{ flexWrap: 'wrap' }}
                >
                {el.map((fdb) => {
                    return  fdb.arp.map((arp) => {
                                if (arp.billing_users) {
                                    return  arp.billing_users.map((billing_user) => {
                                                return (
                                                    <Stack>
                                                        <Stack p={1}>
                                                                <Typography variant="h5">{billing_user.login}</Typography>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                        >
                                                            <Stack p={1}>
                                                                <Typography variant="subtitle2">{int2ip(arp.ip)}</Typography>
                                                                <Typography variant="caption">{fDateTime(arp.timestamp)}</Typography>
                                                            </Stack>
                                                            <Stack p={1}>
                                                                <Typography variant="subtitle2">{fdb.mac}</Typography>
                                                                <Typography variant="caption">{fDateTime(fdb.timestamp)}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                );
                                            })
                                }
                            })
                })}
                </Stack>
                <Divider/>
            </Stack>
        </Box>
        
    );
};

export default HostPortElement;