import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Stack, TextField, Typography, useTheme, Divider, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import BillingService from '../../services/BillingService';

const Payment = ({ open, handleClose, params }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        'error': false,
        'message': false
    })
    const [selectPaymentMethod, setSelectPaymentMethod] = useState(0)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [selectCurrencies, setSelectCurrencies] = useState(0)
    const [payment, setPayment] = useState(0)
    const [commentAdmin, setCommentAdmin] = useState("")
    const [commentUser, setCommentUser] = useState("")

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        const fetcher = async (params) => {
            if (localStorage.getItem('token')) {
                setLoading(false)
                setMessage({
                    'error': false,
                    'message': false
                })
                try {
                    const responseBillingPaymentmethods = await BillingService.billing_paymentmethods('GET', params.billingId)
                    setPaymentMethods(responseBillingPaymentmethods.data)
                    setSelectPaymentMethod(responseBillingPaymentmethods.data[0].id)
                } catch (e) {            
                    console.log(e);
                }
                try {
                    const responseBillingCurrencies = await BillingService.billing_currencies('GET', params.billingId)
                    setCurrencies(responseBillingCurrencies.data)
                    setSelectCurrencies(responseBillingCurrencies.data[0].currency_id)
                } catch (e) {            
                    console.log(e);
                }
                setLoading(true)
            }
        };
        if (open) {
            fetcher(params)
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
            
        }
    }, [open, params]);

    const handleChangePayment = (event) => {
        setPayment(event.target.value)
    }

    const handleSelectPaymentMethods = (event) => {
        setSelectPaymentMethod(event.target.value)
    }
    const handleSelectCurrencies = (event) => {
        setSelectCurrencies(event.target.value)
    }

    const handleChangeAdmin = (event) => {
        setCommentAdmin(event.target.value)
    }

    const handleChangeUser = (event) => {
        setCommentUser(event.target.value)
    }

    const handleSave = async (event) => {
        setLoading(false)
        try {
            const responseBillingPayment = await BillingService.billing_payments(
                'POST',
                params.billingId,
                params.userId,
                params.accountId,
                {
                    "account_id": params.accountId,
                    "user_id": params.userId,
                    "payment_incurrency": Number(payment),
                    "currency_id": selectCurrencies,
                    "actual_date": Date.now() / 1000,
                    // "payment_enter_date": 123,
                    "burn_time": 0,
                    "method": selectPaymentMethod,
                    "admin_comment": commentAdmin,
                    "comment": commentUser,
                    "payment_ext_number": "",
                    "payment_to_invoice": 0,
                    "turn_on_inet": 1
                })
                // handleClose()
                if (responseBillingPayment.status === 200) {
                    setMessage({
                        'error': false,
                        'message': "Payment completed."
                    })
                    setPayment(0)
                }
                else {
                    setMessage({
                        'error': "Error when making payment.",
                        'message': false
                    })  
                }
        } catch (e) {          
            setMessage({
                'error': "Error when making payment!",
                'message': false
            })  
            console.log(e);
        }
        setLoading(true)
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" minWidth={320}>
                    <Typography variant="h5" color={colors.greenAccent[500]}>
                        Make a payment
                    </Typography>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        // p={5}
                    > 
                    <Stack spacing={3}>
                    {!loading && (
                        <LinearProgress />
                    )}
                    {message.error && (
                        <Typography color={colors.redAccent[500]}>
                            {message.error}
                        </Typography>
                    )}
                    {message.message && (
                        <Typography variant="h5" color={colors.greenAccent[500]}>
                            {message.message}
                        </Typography>
                    )}
                    {loading && !message.message && (
                        <Stack spacing={3}>
                                <TextField onChange={handleChangePayment} size="small" label="Payment" variant="standard" type='number' value={payment}/>
                                <Select
                                    value={selectCurrencies}
                                    onChange={handleSelectCurrencies}
                                    label="Currencies"
                                    size="small"
                                    variant="standard"
                                >
                                    {currencies.map((el) => {
                                        return (
                                            <MenuItem value={el.currency_id}>{el.currency_brief_name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <Select
                                    value={selectPaymentMethod}
                                    onChange={handleSelectPaymentMethods}
                                    label="Paymentmethod"
                                    size="small"
                                    variant="standard"
                                >
                                    {paymentMethods.map((el) => {
                                        return (
                                            <MenuItem value={el.id}>{el.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <TextField onChange={handleChangeAdmin} size="small" label="Сomment for administrator" multiline maxRows={2} variant="standard" value={commentAdmin}/>
                                <TextField onChange={handleChangeUser} size="small" label="User comment" multiline maxRows={2} variant="standard" value={commentUser}/>
                        </Stack>
                        )}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {loading && !message.error && !message.message && (
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    )}
                    
                </DialogActions>
            </Dialog>
        </Box>
    );
}
export default Payment;