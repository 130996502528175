import { json } from "react-router-dom";
import $api from "../http";
import { AxiosResponse } from "axios";

export default class BillingService {
    static async billing_users(offset, limit, filter='') {
        return $api.post('/billing_users', {limit, offset, filter})
    }

    static async billing_user_groups(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}/groups`)
    }

    static async billing_user(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}`)
    }

    static async billing_user_accounts(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}/accounts`)
    }

    static async billing_user_servicelinks(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}/servicelinks`)
    }

    static async billing_user_ip_services(billing_id, user_id) {
        return $api.get(`/billing_users/${billing_id}/${user_id}/ipservices`)
    }

    static async billing_payments(method, billing_id, user_id, account_id, data=null) {
        if (method === "GET") {
            return $api.get(`/billings/${billing_id}/users/${user_id}/accounts/${account_id}/payments`)
        }
        if (method === "POST" && data) {
            return $api.post(`/billings/${billing_id}/users/${user_id}/accounts/${account_id}/payments`, data)
        }
    }

    static async billing_promised_payments(method='GET', billing_id, user_id, account_id, data=null) {
        if (method === "GET") {
            return $api.get(`/billings/${billing_id}/users/${user_id}/accounts/${account_id}/promised_payments`)
        }
        if (method === "POST" && data) {
            return $api.post(`/billings/${billing_id}/users/${user_id}/accounts/${account_id}/promised_payments`, data)
        }
    }

    static async billing_currencies(method='GET', billing_id) {
        if (method === "GET") {
            return $api.get(`/billings/${billing_id}/currencies`)
        }
    }

    static async billing_paymentmethods(method='GET', billing_id) {
        if (method === "GET") {
            return $api.get(`/billings/${billing_id}/paymentmethods`)
        }
    }

}