import {Box, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import { styled } from "@mui/material/styles";
import { int2ip } from "../../utils/formatIpAddress";


const StyledElementBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
        
    },
}));

const HostResultElement = ({ host, handleClick}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <StyledElementBox
            onClick={() => {
                console.log("click")
                handleClick({
                    "object": "host",
                    "network_id": host.network,
                    "host": host.ip
                });
            }}
            sx={{
                width: "100%",
                p: 1,
                borderRadius: 1,

                // backgroundColor: id == selectedChatId
                //     ? theme.palette.mode === "light"
                //         ? alpha(theme.palette.primary.main, 0.5)
                //         : theme.palette.primary.main
                //     : theme.palette.mode === "light"
                //         ? "#fff"
                //         : theme.palette.background.paper,
            }}
            p={2}
        >
            <Typography variant="subtitle2">{int2ip(host.ip)} ({host.zbx_name})</Typography>
        </StyledElementBox>
    );
};

export default HostResultElement;