import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SwitchService from "../services/SwitchService";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import {DataGrid} from "@mui/x-data-grid";
import { int2ip } from '../utils/formatIpAddress';

const DiagnosticDialog = ({open, handleClose, params}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [loadingProblem, setLoadingProblem] = useState(false);
    const [loadingArp, setLoadingArp] = useState(false);
    const [loadingFdb, setLoadingFdb] = useState(false);
    const [loadingDiagnostic, setLoadingDiagnostic] = useState(false);
    const [host, setHost] = useState([])
    const [problem, setProblem] = useState([])
    const [arp, setArp] = useState([])
    const [fdb, setFdb] = useState([])
    const [diagnostic, setDiagnostic] = useState("")
    const columnsProblem = [
        {
            field: 'clock',
            headerName: 'Clock',
            flex: 1,
            renderCell: ({ row: { clock } }) => {
                return (
                    new Date(clock * 1000).toLocaleString()
                )
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2
        },
    ];

    const columnsArp = [
        {
            field: 'ip',
            headerName: 'IP-address',
            flex: 1,
        },
        {
            field: 'mac',
            headerName: 'MAC-address',
            flex: 2
        },
    ];

    const columnsFdb = [
        {
            field: 'interface',
            headerName: 'Interface',
            flex: 1,
        },
        {
            field: 'mac',
            headerName: 'MAC-address',
            flex: 2
        },
    ];

    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        const fetcher = async (params) => {
            if (localStorage.getItem('token')) {

                setLoading(false)
                setLoadingProblem(false)
                setLoadingArp(false)
                setLoadingFdb(false)
                setLoadingDiagnostic(false)

                setHost([])
                setProblem([])
                setArp([])
                setFdb([])
                setDiagnostic("")

                try {
                    const responseProblem = await SwitchService.getHostProblem(params.fdb_network, params.sw_ip);
                    setHost(responseProblem.data)
                    setProblem(responseProblem.data.problems)
                } catch (e) {
                    console.log(e);
                }
                setLoadingProblem(true)
                
                try {
                    const responseArp = await SwitchService.getHostArp(params.arp_network, params.gateway, params.arp_ip);
                    setArp(responseArp.data.arp)
                } catch (e) {
                    console.log(e);
                }
                setLoadingArp(true)

                try {
                    const responseFdb = await SwitchService.getHostFdb(params.fdb_network, params.sw_ip, params.sw_port);
                    setFdb(responseFdb.data.fdb)
                } catch (e) {
                    console.log(e);
                }
                setLoadingFdb(true)

                // try {
                //     const responseBits = await SwitchService.getPortBits(params.fdb_network, params.sw_ip, params.sw_port)
                //     // {bits_received: '357999732311', bits_sent: '31994935988'}
                    
                //     // setDiagnostic(responseDiagnostic.data.diagnostic)
                // } catch (e) {
                //     console.log(e);
                // }
                // // setLoadingDiagnostic(true)

                try {
                    const responseDiagnostic = await SwitchService.getPortDiagnostic(params.fdb_network, params.sw_ip, params.sw_port)
                    setDiagnostic(responseDiagnostic.data.diagnostic)
                } catch (e) {
                    console.log(e);
                }
                setLoadingDiagnostic(true)

                setLoading(true)
            }

        };
        if (open) {
            fetcher(params)
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open,params]);

    function getRowId(row) {
        return row.eventid
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{Date().toLocaleString()}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        // p={5}
                    >
                        <Box
                            // display="flex"
                            // m={2}
                            // p={10}
                            alignItems="center"
                            justifyContent="center"
                        >
                            {loading
                                ? <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>Diagnostics completed!!!</Typography>
                                : <LinearProgress /> }
                        </Box>

                        <Box mt={2} ><Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>Diagnosing the node {int2ip(params.sw_ip)}</Typography></Box>
                        <Box
                            // display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {loadingProblem
                                ?
                                    <Box>
                                        <Box mt={2} >
                                            <Typography>Host: {host.host}</Typography>
                                            <Typography>Host name: {host.name}</Typography>
                                            <Typography>Description: {host.description}</Typography>
                                            <Typography>Problems:</Typography>
                                        </Box>
                                        <Box
                                            // display="flex"
                                            // alignItems="center"
                                            // justifyContent="center"
                                        >
                                            <DataGrid
                                                columns={columnsProblem}
                                                rows={problem}
                                                getRowId={getRowId}
                                                disableColumnSorting
                                                disableColumnFilter
                                                scrollbarSize={1}
                                                rowHeight={20}
                                                // autoPageSize
                                                autoHeight={true}
                                                pagination
                                                // disableColumnSelector
                                            />
                                        </Box>
                                    </Box>
                                : <CircularProgress />
                            }
                        </Box>

                        <Box mt={2} ><Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>Arp entries from host {int2ip(params.gateway)}, ip {int2ip(params.arp_ip)}</Typography></Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {loadingArp
                                ?
                                    <DataGrid
                                        columns={columnsArp}
                                        rows={arp}
                                        // getRowId={getRowId}
                                        disableColumnSorting
                                        disableColumnFilter
                                        scrollbarSize={1}
                                        rowHeight={20}
                                        // autoPageSize
                                        autoHeight={true}
                                        pagination
                                        // disableColumnSelector
                                    />
                                :
                                    <CircularProgress />
                            }
                        </Box>

                        <Box mt={2} ><Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>Fdb entries from host {int2ip(params.sw_ip)} interface {params.sw_port}</Typography></Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {loadingFdb
                                ?
                                <DataGrid
                                    columns={columnsFdb}
                                    rows={fdb}
                                    // getRowId={getRowId}
                                    disableColumnSorting
                                    disableColumnFilter
                                    scrollbarSize={1}
                                    rowHeight={20}
                                    // autoPageSize
                                    autoHeight={true}
                                    pagination
                                    // disableColumnSelector
                                />
                                :
                                <CircularProgress />
                            }
                        </Box>

                        <Box mt={2} ><Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>Diagnosing interface {params.sw_port}, host {int2ip(params.sw_ip)}</Typography></Box>

                        <Box>
                            {loadingDiagnostic ?
                                <pre>{diagnostic}</pre>
                                : <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                ><CircularProgress/></Box>
                            }
                        </Box>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
export default DiagnosticDialog;