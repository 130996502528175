import $api from "../http";
import { AxiosResponse } from "axios";

export default class AppealService {

    static async appealsUpdate(offset, limit, filterModel) {
        return $api.post(`/appeals-update`, {limit, offset, filterModel})
    }

    static async getAppeals(offset, limit, filterModel) {
        return $api.post(`/appeals`, {limit, offset, filterModel})
    }

    static async getAppeal(appeal_id) {
        return $api.get(`/appeals/${appeal_id}`)
    }

    static async getAppealGroup() {
        return $api.get(`/appeal_groups`)
    }

}