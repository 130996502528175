import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import {DataGrid} from "@mui/x-data-grid";

const InfoDataGrid = ({ columns, rows=[], getRowId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            m="10px 0 0 0"
            // height="75vh"
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                    alignContent: "center"
                    // borderBottom: "none",
                },
                // "& .MuiDataGrid-columnHeaders": {
                //     backgroundColor: colors.blueAccent[700],
                //     // borderBottom: "none",
                // },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                // "& .MuiDataGrid-footerContainer": {
                //     borderTop: "none",
                //     backgroundColor: colors.blueAccent[700],
                // },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
            }}
        >
            <DataGrid
                columns={columns}
                rows={rows}
                getRowId={getRowId}
                disableColumnSorting
                disableColumnFilter
                // autoPageSize
                autoHeight={true}
                pagination
            />
        </Box>
    );
};

export default InfoDataGrid