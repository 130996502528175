import {useEffect, useState} from "react";
import { Stack } from "@mui/material";
import BillingService from "../services/BillingService";
import DiagnosticDialog from "../components/DiagnosticDialog";
import CircularProgress from '@mui/material/CircularProgress';
import UserComponent from "../components/User/UserComponent"
import Payment from "./Payment/Paument";
import PromisedPayment from "./PromisedPayment/PromisedPayment";

const User = ({ billingId, userId }) => {

    const [isLoading, setIsLoading] = useState(false)

    const [billingUser, setBillingUser] = useState({})
    const [billingUsersGroups, setBillingUsersGroups] = useState([])
    const [billingUserAccounts, setBillingUserAccounts] = useState([])
    const [billingUserServiceLinks, setBillingUserServiceLinks] = useState([])
    const [billingUserIpServices, setBillingUserIpServices] = useState([])
    const [billingUserSwitchesInfo, setBillingUserSwitchesInfo] = useState([])

    const [openDiagnostic, setOpenDiagnostic] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openPromisedPayment, setOpenPromisedPayment] = useState(false);
    const [paramsDiagnostic, setParamsDiagnostic] = useState({});
    const [paramsPayment, setParamsPayment] = useState({});
    const [paramsPromisedPayment, setParamsPromisedPayment] = useState({});

    const handleOpenDiagnostic = (params) => {
        setParamsDiagnostic(params)
        setOpenDiagnostic(true);
    }

    const handleOpenPayment = (params) => {
        setParamsPayment(params)
        setOpenPayment(true);
    }

    const handleOpenPromisedPayment = (params) => {
        setParamsPromisedPayment(params)
        setOpenPromisedPayment(true);
    }

    const handleClose = () => {
        setOpenDiagnostic(false);
    }

    const handleClosePayment = () => {
        setOpenPayment(false);
        fetcher()
    }

    const handleClosePromisedPayment = () => {
        setOpenPromisedPayment(false);
        fetcher()
    }

    const fetcher = async () => {
        try {
            const responseBillingUser = await BillingService.billing_user(billingId, userId);
            setBillingUser(responseBillingUser.data)
        } catch (e) {
            console.log(e);
        }
        try {
            const responseBillingUsersGroups = await BillingService.billing_user_groups(billingId, userId);
            setBillingUsersGroups(responseBillingUsersGroups.data)
        } catch (e) {
            console.log(e);
        }
        try {
            const responseBillingUserServiceLinks = await BillingService.billing_user_servicelinks(billingId, userId)
            setBillingUserServiceLinks(responseBillingUserServiceLinks.data)
        } catch (e) {
            console.log(e);
        }
        try {
            const responseBillingUserAccounts = await BillingService.billing_user_accounts(billingId, userId);
            setBillingUserAccounts(responseBillingUserAccounts.data.accounts)
        } catch (e) {
            console.log(e);
        }
        try {
        const responseBillingUserIpServices = await BillingService.billing_user_ip_services(billingId, userId);
        setBillingUserIpServices(responseBillingUserIpServices.data.ip_services)
        setBillingUserSwitchesInfo(responseBillingUserIpServices.data.switches_information)
        } catch (e) {
            console.log(e);
        }
        setIsLoading(true)
    };

    useEffect(() => {
        setIsLoading(false)
        setBillingUser([])
        setBillingUsersGroups([])
        setBillingUserServiceLinks([])
        setBillingUserAccounts([])
        setBillingUserIpServices([])
        setBillingUserSwitchesInfo([])
        fetcher()
    }, [billingId, userId])

    return (
        <Stack
            sx={{
                width: "100%", flexGrow: 1, overflowY: "scroll" 
            }}
        >
            {/* MODAL Diagnostic */}
            <DiagnosticDialog
                open={openDiagnostic}
                handleClose={handleClose}
                params={paramsDiagnostic}
            >
            </DiagnosticDialog>

            {/* MODAL Payment */}
            <Payment
                open={openPayment}
                handleClose={handleClosePayment}
                params={paramsPayment}
            >
            </Payment>
            {/* MODAL Payment */}
            <PromisedPayment
                open={openPromisedPayment}
                handleClose={handleClosePromisedPayment}
                params={paramsPromisedPayment}
            >
            </PromisedPayment>

            {isLoading ?
                <UserComponent
                    billingId={billingId}
                    handleOpenDiagnostic={handleOpenDiagnostic}
                    handleOpenPayment={handleOpenPayment}
                    handleOpenPromisedPayment={handleOpenPromisedPayment}
                    billingUser={billingUser}
                    billingUserAccounts={billingUserAccounts}
                    billingUserIpServices={billingUserIpServices}
                    billingUserServiceLinks={billingUserServiceLinks}
                    billingUsersGroups={billingUsersGroups}
                    billingUserSwitchesInfo={billingUserSwitchesInfo}
                />
                : 
                <Stack
                alignItems="center"
                justifyContent={"center"}
                sx={{
                    width: "100%",
                    height: "100%",
                    flexGrow: 1,
                }}>
                    <CircularProgress />
                </Stack>
            }
        </Stack>
    )
}
export default User;