import { Stack, Box, Typography } from "@mui/material";
import React, {useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
    // DocMsg,
    // LinkMsg,
    // MediaMsg,
    // ReplyMsg,
    TextMsg,
    Timeline,
} from "./AppealCommentElement";
import ChatHeader from "./Header";
import ChatFooter from "./Footer";

const Conversation = ({ isMobile, menu , comments}) => {
    // const dispatch = useDispatch();
    //
    // const { conversations, current_messages } = useSelector(
    //     (state) => state.conversation.direct_chat
    // );
    // const { room_id } = useSelector((state) => state.app);

    return (
        <Box p={isMobile ? 1 : 3}>
            <Stack spacing={3}>
                {comments.map((el) => {
                    switch (el.type) {
                        case "timeline":
                            return <Timeline el={el} />;
                        
                        case "textmsg":
                            return <TextMsg el={el} menu={menu} />;
                    }
                })}
            </Stack>
        </Box>
    );
};

const ChatComponent = ({ appeal }) => {
    // const {store} = useContext(Context);
    // const appealId = store.appealId
    // const isMobile = useResponsive("between", "md", "xs", "sm");
    const theme = useTheme();

    const messageListRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom of the message list when new messages are added
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }, [appeal]);

    return (
        <Stack
            height={"100%"}
            maxHeight={"100vh"}
            width={false ? "100vw" : "auto"}
        >
            {/*  */}
            <ChatHeader
                appeal={ appeal }
            />
            <Box
                ref={messageListRef}
                width={"100%"}
                sx={{
                    position: "relative",
                    flexGrow: 1,
                    overflowY: "scroll",

                    backgroundColor:
                        theme.palette.mode === "light"
                            ? "#F0F4FA"
                            : theme.palette.background,

                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                }}
            >
                {/*<SimpleBarStyle timeout={500} clickOnTrack={false}>*/}
                {appeal.comments ? (
                    <Conversation menu={true} isMobile={false} comments={appeal.comments} />
                ) : (
                    <Typography>Clear</Typography>
                )}   

                {/*</SimpleBarStyle>*/}
            </Box>

            {/*  */}
            <ChatFooter />
        </Stack>
    );
};

export default ChatComponent;

export { Conversation };