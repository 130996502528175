import { Stack, Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { fTimestamp } from "../../utils/formatTime";

import WarningIcon from '@mui/icons-material/Warning';

const HostProblemElement = ({ problem }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Typography 
            borderRadius={1}
            p={0.2}
            backgroundColor={ colors.redAccent[600] }
        >
            <Stack >
                <WarningIcon />
                <Box>{fTimestamp(problem.clock)}</Box>
                <Box>{problem.name}</Box>
            </Stack>
        </Typography>
    );
};

export default HostProblemElement;