import {
    Stack
} from "@mui/material";

import NothingSelected from "./NothingSelected";
import Host from "../Host";
import User from "../User";

const Content = ({ content=null }) => {

    const renderContent = () => {
        if (content) {
            switch(content.object) {
                case 'user':
                    return <User billingId={content.billing_id} userId={content.user_id} />
                case 'host': 
                    return <Host networkId={content.network_id} hostIp={content.host} />;
                default: 
                    return (
                        <>
                            <Stack
                                sx={{ height: "100%", width: "100%" }}
                                alignItems="center"
                                justifyContent={"center"}
                            >
                                <NothingSelected/>
                            </Stack>
                        </>
                    );
                }
        }
        // else {
        //     return (
        //         <>
        //             <Stack
        //                 sx={{ height: "100%", width: "100%" }}
        //                 alignItems="center"
        //                 justifyContent={"center"}
        //             >
        //                 <NothingSelected/>
        //             </Stack>
        //         </>
        //     );
            
        // }
    }

    return (
        <>
            {renderContent()}
        </>
    );
};

export default Content;