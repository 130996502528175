import {
    Box,
    Typography,
    useTheme,
    Divider,
    Avatar,
    Stack
} from "@mui/material";
import InfoDataGrid from "../../components/InfoDataGrid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import HostProblemElement from "./HostProblemElement";
import { tokens } from "../../theme";
import { int2ip } from "../../utils/formatIpAddress";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import GroupIcon from '@mui/icons-material/Group';
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HostPortElement from "./HostPortElement";


const HostComponent = ({ networkId, host, problem, hostDownlinks, hostPorts }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columnsProblem = [
        {
            field: 'clock',
            headerName: 'Clock',
            flex: 1,
            renderCell: ({ row: { clock } }) => {
                return (
                    new Date(clock * 1000).toLocaleString()
                )
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2
        },
    ];

    const columnsHostDownlinks = [
        // {
        //     field: 'clock',
        //     headerName: 'Clock',
        //     flex: 1,
        //     renderCell: ({ row: { clock } }) => {
        //         return (
        //             new Date(clock * 1000).toLocaleString()
        //         )
        //     }
        // },
        {
            field: 'portparents',
            headerName: 'Port',
            flex: 1
        },
        {
            field: 'ip',
            headerName: 'Host',
            flex: 10,
            renderCell: ({ row: { ip, uplink, vendor } }) => {
                return (
                    int2ip(ip) + ':' + uplink + '(' + vendor +')'
                )
            }
        },
    ];

    const columnsHostPorts = [
        // {
        //     field: 'clock',
        //     headerName: 'Clock',
        //     flex: 1,
        //     renderCell: ({ row: { clock } }) => {
        //         return (
        //             new Date(clock * 1000).toLocaleString()
        //         )
        //     }
        // },
        {
            field: 'port',
            headerName: 'Port',
            flex: 1
        },
        {
            field: 'mac',
            headerName: 'Fdb',
            flex: 4,
                renderCell: ({ row: { mac, timestamp } }) => {
                    return (
                        <Box>
                            <Typography>
                                { mac }
                            </Typography>
                            <Typography>
                                { timestamp }
                            </Typography>
                        </Box>
                    )
                }
        },
        // {
        //     field: 'ip',
        //     headerName: 'Host',
        //     flex: 10,
        //     renderCell: ({ row: { ip, uplink, vendor } }) => {
        //         return (
        //             int2ip(ip) + ':' + uplink + '(' + vendor +')'
        //         )
        //     }
        // },
    ];

    return (
        <Stack
            sx={{
                width: "100%", flexGrow: 1, overflowY: "scroll" 
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    borderRadius: 1,
                    // backgroundColor: id == selectedChatId
                    //     ? theme.palette.mode === "light"
                    //         ? alpha(theme.palette.primary.main, 0.5)
                    //         : theme.palette.primary.main
                    //     : theme.palette.mode === "light"
                    //         ? "#fff"
                    //         : theme.palette.background.paper,
                }}
                p={2}
            >
                <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                >
                    <Stack direction="row" spacing={2}>
                        {" "}
                        
                        {/* <Avatar
                            alt="S W"
                            src={"img"}
                            sx={{
                                position: "static"
                            }}
                        /> */}
                        
                        <Stack spacing={0.3}>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                sx={{ color: colors.grey[100] }}
                            >
                                {int2ip(host.ip)} ({host.mac})
                            </Typography>
                            <Typography variant="caption">Vendor: {host.vendor}</Typography>
                            
                        </Stack>
                    </Stack>
                    <Stack spacing={2} alignItems={"center"}>
                        <Typography sx={{ fontWeight: 600 }} variant="caption">
                            { host.updated_on }
                        </Typography>
                        {/* <Badge
                            className="unread-count"
                            color="primary"
                            badgeContent="changed"
                        /> */}
                    </Stack>
                    
                </Stack>
                <Divider />
                <Stack>
                    <Typography variant="caption">Zabbix name: {host.zbx_name}</Typography>
                    <Typography variant="caption">Zabbix description: {host.zbx_description}</Typography>
                    <Divider />
                    <Typography variant="caption">uplink: {host.uplink}</Typography>
                    <Typography variant="caption">connect_to: <Link to={`/search/host/${host.network}/${host.ipparents}`}>{int2ip(host.ipparents)}:{host.portparents}</Link></Typography>
                </Stack>
                <Divider />
                <Stack m={"10px"} spacing={0.5}>
                    <Stack 
                        direction="row"
                        spacing={{ xs: 1, sm: 2 }}
                        useFlexGap
                        sx={{ flexWrap: 'wrap' }}
                    >
                        {problem.length === 0 
                            ?   <Typography
                                    fontStyle="italic"
                                    sx={{ color: colors.greenAccent[600] }}
                                >
                                    <CheckBoxIcon />
                                    No problems found with the host
                                </Typography>
                            : problem.map((el) => {
                                return <HostProblemElement 
                                    problem={el}
                                />;
                            })
                        }
                    </Stack>
                </Stack>
                <Divider />
                <Stack m={"10px"} spacing={0.5}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SettingsEthernetIcon />
                            {/* <Typography color={colors.greenAccent[500]} variant="h5">
                                Connections
                            </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <InfoDataGrid
                                        columns={columnsHostDownlinks}
                                        rows={hostDownlinks}
                                        // getRowId={getRowId}
                                    />
                         </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <GroupIcon />
                            {/* <Typography color={colors.greenAccent[500]} variant="h5">
                                Connection users
                            </Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.entries(hostPorts).map( ([key, value]) => {
                                return  <HostPortElement
                                            port={key}
                                            el={value}
                                        />;
                            })}
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </Box>
        </Stack>
    );
};

export default HostComponent;