import {
    Box,
    Typography,
    useTheme,
    Stack
} from "@mui/material";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import {Link} from 'react-router-dom';
import ChatComponent from "./Chat";
import NoChat from "./NoAppeal";
import AppealService from "../../services/AppealService";


const Appeal = ({ appealId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [appeal, setAppeal] = useState(null)

    useEffect(() => {
        const subscribe = async () => {
            if (appealId) {
                const appeal = await AppealService.getAppeal(appealId);
                setAppeal(appeal.data);
            }
        }
        subscribe()
    }, [ appealId ])

    return (
        <Box
            sx={{
                minWidth: "650px"
            }}
            backgroundColor={colors.primary[400]}
        >
            {appeal ? (
                <ChatComponent 
                    appeal={ appeal }
                />
            ) : (
            <Stack
                spacing={2}
                sx={{ height: "100%", width: "100%" }}
                alignItems="center"
                justifyContent={"center"}
            >
                <NoChat />
                <Typography variant="subtitle2">
                    Select a conversation or start a{" "}
                    <Link
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: "none",
                        }}
                        to="/"
                    >
                        new one
                    </Link>
                </Typography>
            </Stack>
            )}
        </Box>
    )
};

export default Appeal;