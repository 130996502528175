import $api from "../http";
import { AxiosResponse } from "axios";

export default class ProviderService {

    static async getProviders() {
        return $api.get(`/providers`)
    }

    // static async getNetwork(network_id) {
    //     return $api.get(`/networks/${network_id}`)
    // }

}