import React, {useContext, useState} from "react";
import {Context} from "../../index";
import { Stack, IconButton, InputAdornment, Button, Box, TextField } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {observer} from "mobx-react-lite";

const LoginForm = () => {
    const {store} = useContext(Context);
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const [showPassword, setShowPassword] = useState(false);
    const {isLoading} = store.isLoading;

    return (
        <Box>
            <Stack spacing={3}>
                {/* {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )} */}
                <TextField
                    onChange={e => setLogin(e.target.value)}
                    value={login}
                    type="text"
                    placeholder="login"
                />
                <TextField
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                />
                <Stack alignItems="flex-end" sx={{ my: 2 }}>
                    {/* <Link component={RouterLink} to="/auth/reset-password" variant="body2" color="inherit" underline="always"> */}
                    {/* Forgot password? */}
                    {/* </Link> */}
                </Stack>
                <Button
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    onClick={() => store.login(login, password)}
                    sx={{
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light" ? "common.white" : "grey.800",
                        "&:hover": {
                          bgcolor: "text.primary",
                          color: (theme) =>
                            theme.palette.mode === "light" ? "common.white" : "grey.800",
                        },
                      }}
                >
                    Логин
                </Button>
            </Stack>
        </Box>
    )
}

export default observer(LoginForm)