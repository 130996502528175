import {useContext, useState} from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import RouterIcon from '@mui/icons-material/Router';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ForumIcon from '@mui/icons-material/Forum';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import {Context} from "../../index";

const Item = ({ title, to, icon, selected, setSelected, setToggled }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => {
                setSelected(title)
                setToggled(false)
            }}
            icon={icon}
            component={<Link to={to} />}
        >
            <Typography>{title}</Typography>
            
        </MenuItem>
    );
};

const AppSidebar = ({toggled, setToggled, isCollapsed, setIsCollapsed, broken, setBroken}) => {
    const {store} = useContext(Context);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const user = store.user

    // const [isCollapsed, setIsCollapsed] = useState(false);
    // const [toggled, setToggled] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return (
        <Box
            style={{ display: 'flex', height: '100%' }}
        >
            <Sidebar 
                backgroundColor={colors.primary[400]}
                onBackdropClick={() => setToggled(false)}
                onBreakPoint={ setBroken }
                collapsed={isCollapsed}
                toggled={toggled} breakPoint="md"
            >
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    {!isCollapsed && (
                        <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            ml="15px"
                            style={{
                                margin: "10px 0 20px 0",
                                color: colors.grey[100],
                            }}
                        >
                            <Typography variant="h3" color={colors.grey[100]}>
                                CRM
                            </Typography>
                            {broken ? 
                                <IconButton onClick={() => setToggled(false)}>
                                    <CloseIcon />
                                </IconButton>
                                : undefined
                            }                       
                        </Box>
                        <Box mb="25px">
                            {/*<Box display="flex" justifyContent="center" alignItems="center">*/}
                            {/*    <img*/}
                            {/*        alt="profile-user"*/}
                            {/*        width="100px"*/}
                            {/*        height="100px"*/}
                            {/*        src={`../../assets/user.png`}*/}
                            {/*        style={{ cursor: "pointer", borderRadius: "50%" }}*/}
                            {/*    />*/}
                            {/*</Box>*/}
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {store.user.name}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    Department
                                </Typography>
                            </Box>
                        </Box>
                        </>
                    )}
             
                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            setToggled={setToggled}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            DATA
                        </Typography>
                        {user.allowed_functions.indexOf( 'show_data_billings' ) !== -1 && (
                            <Item
                                title="Billings"
                                to="/billings"
                                icon={<PeopleOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                setToggled={setToggled}
                            />
                        )}
                        {user.allowed_functions.indexOf( 'show_data_switches' ) !== -1 && (
                        <Item
                            title="Switches"
                            to="/switches"
                            icon={<RouterIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            setToggled={setToggled}
                        />
                        )}
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            APP
                        </Typography>
                        {user.allowed_functions.indexOf( 'show_app_search' ) !== -1 && (
                            <Item
                                title="Search"
                                to="/search"
                                icon={<SearchIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                setToggled={setToggled}
                            />
                        )}
                        {user.allowed_functions.indexOf( 'show_app_appeals' ) !== -1 && (
                            <Item
                            title="Appeals"
                            to="/appeals"
                            icon={<ForumIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            setToggled={setToggled}
                            />
                        )}
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            REPORTS
                        </Typography>
                        {user.allowed_functions.indexOf( 'show_main_report_billings' ) !== -1 && (
                            <Item
                                title="Main report billing"
                                to="/main_report_billing"
                                icon={<RequestPageIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                setToggled={setToggled}
                            />
                        )}
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            PROFILE
                        </Typography>
                        <Item
                            title="Profile"
                            to="/profile"
                            icon={<PersonOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            setToggled={setToggled}
                        />
                        <Item
                            title="Notification"
                            to="/notification"
                            icon={<NotificationsOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            setToggled={setToggled}
                        />
                        <Item
                            title="Settings"
                            to="/settings"
                            icon={<SettingsOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            setToggled={setToggled}
                        />
                        <MenuItem
                            style={{
                                color: colors.grey[100],
                            }}
                            onClick={() => store.logout()}
                            icon={<ExitToAppIcon />}
                        >
                            <Typography>Exit</Typography>
                        </MenuItem>
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default AppSidebar;