import {
    Box,
    useTheme,
    Stack
} from "@mui/material";
import LiveSearch from "../LiveSearch/LiveSearch";
import Content from "./Content";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const GlobalLiveSearch = ({ value="", content=null }) => {
    const theme = useTheme();
    const params = useParams();

    const [openObject, setOpenObject] = useState({})

    const handleClick = (object) => {
        setOpenObject(object)
    };

    useEffect(() => {
        switch(params.object) {
            case 'user':
                setOpenObject({
                    'object': params.object,
                    'billing_id': params.source,
                    'user_id': params.id
                });
                break;
            case 'host': 
                setOpenObject({
                    'object': params.object,
                    'network_id': params.source,
                    'host': params.id
                });
                break;
            default:
                setOpenObject(content)
        }
    }, [ content, params.id, params.object, params.source ]);

    // useEffect(() => {
    //     setOpenObject(content)
    // },[content]);

    return (
        <Box
            sx={{
                // position: "relative",
                width: "100%",
                minWidth: "320px",
                // width: true ? 320 : "100vw",
                backgroundColor:
                    theme.palette.mode === "light"
                        ? "#F8FAFF"
                        : theme.palette.background,
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
        >
            <Stack spacing={2} sx={{ maxHeight: "100%"}}>
                    <Stack sx={{ width: "100%"}}>
                        <LiveSearch 
                            value={value}
                            handleClick={handleClick}
                        />
                    </Stack>
                    <Content 
                        content={openObject}
                    />
                </Stack>
        </Box>
    );
};

export default GlobalLiveSearch;