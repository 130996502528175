import { Box, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled, alpha } from "@mui/material/styles";

const StyledChatBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const ServiceLinks = ({ serviceLink }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    // const [expanded, setExpanded] = useState("personalAccounts");
    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    return (
        <StyledChatBox
            // onClick={() => {
            //     handleAppealClick(id);
            // }}
            sx={{
                width: "100%",

                borderRadius: 1,

                backgroundColor: 0
                    ? theme.palette.mode === "light"
                        ? alpha(theme.palette.primary.main, 0.5)
                        : theme.palette.primary.main
                    : theme.palette.mode === "light"
                        ? "#fff"
                        : theme.palette.background.paper,
            }}
            // p={2}
        >
            <Accordion>
                <AccordionSummary>
                    <Stack
                            direction="row"
                            spacing={2}
                            alignItems={"center"}
                            justifyContent="space-between"
                        >
                            <Typography
                                borderRadius={1}
                                p={0.5}
                                backgroundColor={colors.greenAccent[500]}
                            >
                                {serviceLink.service_type}
                            </Typography>
                            <Stack>
                                <Typography
                                    variant="h5"
                                >
                                    {serviceLink.service_name}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    fontStyle="italic"
                                    sx={{ color: colors.greenAccent[600] }}
                                >
                                    {serviceLink.comment}
                                </Typography>
                            </Stack>
                            {/* <Typography
                                    variant="h5"
                                    fontStyle="italic"
                                    sx={{ color: colors.greenAccent[600] }}
                                >
                                    Price: {serviceLink.price ? serviceLink.price : "Not specified"}
                            </Typography> */}
                        
                    </Stack>
                </AccordionSummary>
                {/* <AccordionDetails>
                        Tariff
                </AccordionDetails> */}
            </Accordion>
        </StyledChatBox>
    );
};

export default ServiceLinks;