import {
    Box,
    Stack
} from "@mui/material";
import Header from "../../components/Header";
import { useState } from "react";
import Appeals from "../../sections/Appeals/Appeals";
import Appeal from "../../sections/Appeal/Appeal";
import GlobalLiveSearch from "../../sections/GlobalLiveSearch/GlobalLiveSearch";

const AppAppeals = () => {
    const [content, setContent] = useState({})
    const [appealId, setAppealId] = useState(null)
    
    const handleAppealClick = (appeal_id) => {
        setAppealId(appeal_id)
    };

    const handleAppealLoading = ( appeal ) => {
        if (appeal.source_type === 'incoming_call') {
            setContent({
                "object": "user",
                "billing_id": appeal.source_object.billing_id,
                "user_id": appeal.source_object.user_id
            })
        }
        else {
            setContent({})
        }
    }

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Appeals" subtitle="Appeals" />
            </Box>
            {/* CONTEXT */}
            <Stack direction="row" alignItems="stretch" sx={{ width: "100%", maxHeight: "100%", minWidth: "320px" }} spacing={1.5} >
                {/* appeals */}
                <Appeals
                    appealId={appealId}
                    onAppealClick={handleAppealClick}
                />
                {/* appeals */}

                {/*chat*/}
                <Appeal 
                    appealId={appealId}
                    onLoading={handleAppealLoading}
                />
                {/*chat*/}
                
                {/* info */}
                <GlobalLiveSearch 
                    content={content}
                />
                {/* info */}
            </Stack>
        </Box>
    )
};

export default AppAppeals;