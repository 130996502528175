import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
    useTheme,
    Table,
    List,
    ListItem,
    Divider,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack, InputBase, CircularProgress
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import { useState, useEffect, Fragment } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import BillingService from "../../services/BillingService";
import SwitchService from "../../services/SwitchService";
import UserResultElement from "./UserResultElement";
import HostResultElement from "./HostResultElement";

const LiveSearch = ({ placeholder="Search", value=null, handleClick }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [openResult, setOpenResult] = useState(false)
    const [search, setSearch] = useState(value);
    const [result, setResult] = useState({
        "users" : [],
        "hosts" : []
    })

    useEffect(() => {
        const fetcher = async () => {
            if (localStorage.getItem('token') || value) {
                // setOpenResult(true)
                try {
                    const billing_users = await BillingService.billing_users(0, 10, search);
                    const hosts = await SwitchService.getHosts(0, 10, search);
                    setResult({
                        "users": billing_users.data.billingUsers,
                        "hosts": hosts.data.switches
                    });
                    
                } catch (e) {
                    console.log(e);
                }
            }
        };
        fetcher()
    }, [search]);

    useEffect(() => {
        // setOpenResult(true)
        setSearch(value)
    }, [value]);

    const handleClick2 = (object) => {
        setOpenResult(false)
        handleClick(object)
    };

    return (
        <Stack
            onFocus={() => {
                setOpenResult(true)
                }
            }
            // onBlur={() => {
            //     setOpenResult(false)
            //     }
            // }
            sx={{
                position: "relative"
            }}
        >
            <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
                m={1}
                sx={{
                    position: "relative"
                }}
            >
                <InputBase
                    sx={{ ml: 2, flex: 1 }}
                    placeholder={placeholder}
                    onChange={e => setSearch(e.target.value)}
                    // onFocus={() => {
                    //     setOpenResult(true)
                    //     }
                    // }
                    // onBlur={() => {
                    //     setOpenResult(false)
                    //     }
                    // }
                    value={search}
                />
                <Box sx={{ p: 1 }}>
                    <SearchIcon />
                </Box>
            </Box>
                {openResult ? (
                    <Stack
                        backgroundColor={colors.primary[400]}
                        sx={                
                            {
                                // backgroundColor: theme.palette.primary.main,
                                m: 2,
                                p: 2,
                                // flexGrow: 1,
                                overflowY: "scroll",
                                minWidth: 150,
                                maxWidth: "90%",
                                maxHeight: 250,
                                width: "max-content",
                                // position: "absolute",
                                left: 0,
                                top: 40,
                                // zindex: 9999,
                            }
                        }>
                            {result['users'].map((el) => {
                                return <UserResultElement user={el} handleClick={handleClick2} />;
                            })}
                            {result['hosts'].map((el) => {
                                return <HostResultElement host={el} handleClick={handleClick2} />;
                            })}
                    </Stack>
                    ) : (
                        <></>
                    )
                }
        </Stack>
    );
};

export default LiveSearch;