import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Stack, TextField, Typography, useTheme, Divider } from "@mui/material";
import { tokens } from "../../theme";
import BillingService from '../../services/BillingService';

const PromisedPayment = ({ open, handleClose, params }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        'error': false,
        'message': false
    })
    const [promisedPayments, setPromisedPayments] = useState({})
    const [payment, setPayment] = useState(0)

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        const fetcher = async (params) => {
            if (localStorage.getItem('token')) {
                setLoading(false)
                setMessage({
                    'error': false,
                    'message': false
                })
                try {
                    const responseBillingPromisedPayments = await BillingService.billing_promised_payments('GET', params.billingId, params.userId, params.accountId)
                    setPromisedPayments(responseBillingPromisedPayments.data)
                    if (!responseBillingPromisedPayments.data.is_possible) {
                        setMessage({
                            'error': "Promised payment not avaible for this user.",
                            'message': false
                        })
                    }
                } catch (e) {       
                    setMessage({
                        'error': "Promised payment not avaible for this user.",
                        'message': false
                    })     
                    console.log(e);
                }
                setLoading(true)
            }
        };
        if (open) {
            fetcher(params)
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
            
        }
    }, [open, params]);

    const handleChangePayment = (event) => {
        setPayment(event.target.value)
    }

    const handleSave = async (event) => {
        setLoading(false)
        try {
            const responseBillingPromisedPayments = await BillingService.billing_promised_payments(
                'POST',
                params.billingId,
                params.userId,
                params.accountId,
                {
                    "account_id": params.accountId,
                    "user_id": params.userId,
                    "value": Number(payment),
                })
                // handleClose()
                if (responseBillingPromisedPayments.status === 200) {
                    setMessage({
                        'error': false,
                        'message': "Promised payment activated."
                    })
                    setPayment(0)
                }
                else {
                    setMessage({
                        'error': "Error when activating promised payment.",
                        'message': false
                    })  
                }
        } catch (e) {          
            setMessage({
                'error': "Error when activating promised payment!",
                'message': false
            })  
            console.log(e);
        }
        setLoading(true)
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" minWidth={320}>
                    <Typography variant="h5" color={colors.greenAccent[500]}>
                        Promised payment
                    </Typography>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        // p={5}
                    > 
                    <Stack spacing={3}>
                    {!loading && (
                        <LinearProgress />
                    )}
                    {message.error && (
                        <Typography color={colors.redAccent[500]}>
                            {message.error}
                        </Typography>
                    )}
                    {message.message && (
                        <Typography variant="h5" color={colors.greenAccent[500]}>
                            {message.message}
                        </Typography>
                    )}
                    {loading && !message.error && !message.message && (
                        <Stack spacing={1.5}>
                                <TextField onChange={handleChangePayment} size="small" label="Payment" variant="standard" type='number' value={payment}/>
                                <Typography>Max payment: {promisedPayments.max_value}</Typography>
                                <Typography>Expires in (days): {promisedPayments.max_duration}</Typography>
                                <Typography>Interval between uses (days): {promisedPayments.interval_duration}</Typography>
                                <Typography>Min balance: {promisedPayments.min_balance}</Typography>
                                <Typography>Free if balance over: {promisedPayments.free_balance}</Typography>
                        </Stack>
                    )}
                    </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {loading && !message.error && !message.message && (
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    )}
                    
                </DialogActions>
            </Dialog>
        </Box>
    );
}
export default PromisedPayment;

