import {Avatar, Box, Badge, Stack, Divider, ListItemButton, ListItemAvatar, ListItemText, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import {Fragment, useContext} from "react";
// import ProgressCircle from "./ProgressCircle";
import { styled, alpha } from "@mui/material/styles";
import {Context} from "../../index";
import truncateString from "../../utils/trancate";
import { fToNow, fTime } from "../../utils/formatTime";


const StyledElementBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const UserResultElement = ({ user, handleClick}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const {store} = useContext(Context);
    // const selectedChatId = store.appealId?.toString();
    // let isSelected = +selectedChatId === id;

    // if (!selectedChatId) {
    //     isSelected = false;
    // }
    return (
        <StyledElementBox
            onClick={() => {
                handleClick({
                    "object": "user",
                    "billing_id": user.billing_id,
                    "user_id": user.user_id
                });
            }}
            sx={{
                width: "100%",
                p: 1,
                borderRadius: 1,

                // backgroundColor: id == selectedChatId
                //     ? theme.palette.mode === "light"
                //         ? alpha(theme.palette.primary.main, 0.5)
                //         : theme.palette.primary.main
                //     : theme.palette.mode === "light"
                //         ? "#fff"
                //         : theme.palette.background.paper,
            }}
            p={2}
        >
            <Typography variant="subtitle2">{user.user_id} {user.login} {user.full_name}</Typography>
        </StyledElementBox>
    );
};

export default UserResultElement;