import { Box } from "@mui/material";

import Header from "../../components/Header";
import GlobalLiveSearch from "../../sections/GlobalLiveSearch/GlobalLiveSearch";

const GlobalSearch = () => {

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Search" subtitle="Global search." />
            </Box>
            {/* CONTEXT */}
            <GlobalLiveSearch />
        </Box>
    )
};

export default GlobalSearch;