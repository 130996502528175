import { Box, IconButton, Stack,  Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useState, useContext } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from '@mui/material/CircularProgress';
import InfoDataGrid from "../../components/InfoDataGrid";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

import { alpha } from "@mui/material/styles";
import { fTimestamp } from "../../utils/formatTime";
import BillingService from "../../services/BillingService";
import {Context} from "../../index";


const PersonalAccount = ({ personalAccount, billingId, userId, handleOpenPayment, handleOpenPromisedPayment }) => {
    const {store} = useContext(Context);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const user = store.user
    
    const [isLoading, setIsLoading] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const [billingAccountPayments, setBillingAccountPayments] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])

    const columnsPersonalAccountPayments = [
        { field: 'payment_enter_date',
            headerName: 'Payment time',
            flex: 1,
            renderCell: ({ row: { payment_enter_date } }) => {
                return (
                    <Typography>
                        {fTimestamp(payment_enter_date)}
                    </Typography>
                )
            },
            cellClassName: "name-column--cell"
        },
        { field: 'payment_absolute', headerName: 'Sum', flex: 1, },
        { field: 'method', headerName: 'Payment method', flex: 1, 
            renderCell: ({ row: { method }}) => {
                return (
                    <Typography>
                        ({method}) {paymentMethods.filter( element => element.id === method )[0].name}
                    </Typography>
                )
            },
            cellClassName: "name-column--cell"
        },
        { field: 'comments_for_admins', headerName: 'Comment for the administrator', flex: 1, },
        { field: 'comments_for_user', headerName: 'Comment for the user', flex: 1, },
    ];

    const handleChange = () => {
        setIsExpanded(!isExpanded)
        if (!isExpanded) {
            fetcher()
        }
    };

    const fetcher = async () => {
        if (localStorage.getItem('token')) {
            setIsLoading(false)
            try {
                const responseBillingPaymentmethods = await BillingService.billing_paymentmethods('GET', billingId)
                setPaymentMethods(responseBillingPaymentmethods.data)
            } catch (e) {            
                console.log(e);
            }
            try {
                const responseBillingPayments = await BillingService.billing_payments('GET', billingId, userId, personalAccount.account_id)
                setBillingAccountPayments(responseBillingPayments.data)
            } catch (e) {            
                console.log(e);
            }
            setIsLoading(true)
        }
    };

    return (
        <Box
            // onClick={() => {
            //     handleAppealClick(id);
            // }}
            sx={{
                width: "100%",

                borderRadius: 1,

                backgroundColor: 0
                    ? theme.palette.mode === "light"
                        ? alpha(theme.palette.primary.main, 0.5)
                        : theme.palette.primary.main
                    : theme.palette.mode === "light"
                        ? "#fff"
                        : theme.palette.background.paper,
            }}
            // p={2}
        >
            <Accordion expanded={isExpanded}>
                <AccordionSummary expandIcon={<ExpandMoreIcon onClick={handleChange}/>}>
                    <Box sx={{
                        width: "100%"
                    }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems={"center"}
                            justifyContent="space-between"
                        >
                            <Stack direction="row" spacing={2}>
                                <Typography
                                    borderRadius={1}
                                    p={0.5}
                                    alignContent={"center"}
                                    backgroundColor={colors.greenAccent[500]}
                                >
                                    {personalAccount.account_id}
                                </Typography>
                                <Stack>
                                    <Typography
                                        color={personalAccount.int_status
                                            ? colors.greenAccent[500]
                                            : colors.redAccent[500]
                                        }
                                        variant="h5"
                                    >
                                        {personalAccount.balance} ₽ (Credit: {personalAccount.credit} ₽)
                                    </Typography>
                                    <Typography color={personalAccount.block_id === 0
                                            ? colors.greenAccent[500]
                                            : colors.redAccent[500]
                                        }
                                        variant="h5"
                                    >
                                        {personalAccount.block_type === null
                                            ? "Active"
                                            : personalAccount.block_type === 1
                                                ? "System lock"
                                                : personalAccount.block_type === 2
                                                    ? "Administrative blocking"
                                                    : personalAccount.block_type === 2
                                                        ? "User lock"
                                                        : "Unknown"
                                        }               
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems={"center"}>
                                {user.allowed_functions.indexOf( 'make_promised_payments' ) !== -1 && (
                                    <IconButton onClick={() => {
                                        handleOpenPromisedPayment({
                                            "billingId": billingId,
                                            "userId": userId,
                                            "accountId": personalAccount.account_id
                                        })
                                    }}>
                                        <CreditCardIcon />
                                    </IconButton>
                                )}
                                {user.allowed_functions.indexOf( 'make_payment' ) !== -1 && (
                                    <IconButton onClick={() => {
                                        handleOpenPayment({
                                            "billingId": billingId,
                                            "userId": userId,
                                            "accountId": personalAccount.account_id
                                        })
                                    }}>
                                        <CreditScoreIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        </Stack>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {isLoading
                        ?   <Stack>
                                {/* <Stack direction="row" spacing={2} alignItems={"center"} justifyContent={"flex-end"}>
                                    {user.allowed_functions.indexOf( 'make_promised_payments' ) !== -1 && (
                                        <IconButton onClick={() => {
                                            handleOpenPromisedPayment({
                                                "billingId": billingId,
                                                "userId": userId,
                                                "accountId": personalAccount.account_id,
                                                "promisedPayments": promisedPayments
                                            })
                                        }}>
                                            <CreditCardIcon />
                                        </IconButton>
                                    )}
                                        
                                    {user.allowed_functions.indexOf( 'make_payment' ) !== -1 && (
                                        <IconButton onClick={() => {
                                            handleOpenPayment({
                                                "billingId": billingId,
                                                "userId": userId,
                                                "accountId": personalAccount.account_id
                                            })
                                        }}>
                                            <CreditScoreIcon />
                                        </IconButton>
                                    )}
                                    
                                </Stack> */}
                                <InfoDataGrid
                                    columns={columnsPersonalAccountPayments}
                                    rows={billingAccountPayments}
                                    // getRowId={getRowId()}
                                />
                            </Stack>
                        :   <Stack
                                alignItems="center"
                                justifyContent={"center"}
                                sx={{
                                    width: "100%",
                                    flexGrow: 1,
                                }}
                            >
                                <CircularProgress />
                            </Stack>
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default PersonalAccount;